import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Logo from "../Assets/Images/logo1.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Link,
} from "react-router-dom";
import { useEffect } from "react";

export default function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#" }}>
        <nav className="bg-brimary navbar">
          <div className="navbar-container container lg:pl-5 lg:pr-10">
            <div className="navbar-logo">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " activated" : "")
                }
                onClick={closeMobileMenu}
              >
                <img src={Logo} alt="Logo" />
              </NavLink>
            </div>

            <div className="menu-icon text-white" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <AnchorLink
                  href="/home"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  HOME
                </AnchorLink>
              </li>
              <li className="nav-item">
                <AnchorLink
                  href="/about"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  ABOUT US
                </AnchorLink>
              </li>
              <li className="nav-item">
                <AnchorLink
                  href="/what-we-do"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  WHAT WE DO
                </AnchorLink>
              </li>
              <li className="nav-item">
                <AnchorLink
                  href="/portfolio"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  PORTFOLIO
                </AnchorLink>
              </li>
              <li className="nav-item">
                <AnchorLink
                  href="/contact"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  CONTACT US
                </AnchorLink>
              </li>

              <li id="google_translate_element"></li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}
