import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaInstagram } from "react-icons/fa";
import { TbBrandTelegram } from "react-icons/tb";
import { RxDiscordLogo } from "react-icons/rx";
import { FiTwitter } from "react-icons/fi";
import { SlSocialLinkedin } from "react-icons/sl";
import Img1 from "../Assets/Images/service1.png";
import Img2 from "../Assets/Images/service2.png";
import Img3 from "../Assets/Images/service3.png";
import Img4 from "../Assets/Images/service4.png";
import Img5 from "../Assets/Images/swyype.png";
import Img6 from "../Assets/Images/healthgo.svg";
import Img7 from "../Assets/Images/groverse.png";
import Img8 from "../Assets/Images/mediical-storee.png";
import Img9 from "../Assets/Images/smartbuilder.png";
import Img10 from "../Assets/Images/shuriken-labs.png";
import Img11 from "../Assets/Images/thrift.svg";
import Img12 from "../Assets/Images/marsos.png";
import Img13 from "../Assets/Images/contentlock.png";
import Img14 from "../Assets/Images/toro.png";
import Img15 from "../Assets/Images/workspace.png";
import Img16 from "../Assets/Images/lawyers.png";
import Team from "../Components/Team";
export default function Home() {
  return (
    <>
      <div className="display">
        <div className="hero lg:p-20 p-5" id="home">
          <p className="hero-heading text-white">
            <span className="secondary-text">Innovations</span> and Everything
            In-between
          </p>
          <p className="text-white hero-sub pt-5 lg:pl-20 lg:pr-20">
            Deverse Labs was established in response to the demand of more
            blockchain developers that uses blockchain to create products that
            progress technology
          </p>
          <a href="https://discord.gg/rPDcdCED">
            <button className=" btn mt-10 text-white font-bold py-2 px-4 rounded ">
              Join us
              {/* <HiArrowNarrowRight /> */}
            </button>
          </a>
        </div>
        <div className="about lg:p-20 p-10" id="about">
          <p className="text-primary about-head ">About</p>
          <p className="about-subheading text-white mt-5">WHO WE ARE</p>
          <div className=" mt-5 ">
            <p className="text-white about-text">
              Deverse Labs is a blockchain community building and investing in
              people with disruptive ideas across the blockchain space, with
              JavaScript, Move, Cosmos Wasm, and
              <span className="text-primary"> more</span>. At Deverse Lab, our
              <span className="text-primary"> vision</span> is to be the biggest
              blockchain Labs in
              <span className="text-primary"> Nigeria</span>, giving policy
              advisory to the government and beyond.
            </p>
          </div>
        </div>
        <div className="services p-5 pb-10 lg:pl-20  lg:pr-20 " id="what-we-do">
          <p className="text-white services-heading pb-20">WHAT WE DO</p>

          <div className="lg:grid lg:grid-cols-2 text-left">
            <div className="mb-10 grid grid-cols-5">
              <img
                className="col-span-2 services-icon transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                src={Img1}
                alt=""
              />
              <p className="services-text text-white text-left col-span-3 mt-4 ml-3">
                Web 3.0 Community
              </p>
            </div>
            <div className="mb-10 grid grid-cols-5">
              <img
                className="col-span-2 services-icon transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                src={Img2}
                alt=""
              />
              <p className="services-text text-white text-left col-span-3 mt-4 ml-3">
                Blockchain Development Team
              </p>
            </div>
            <div className="mb-10 grid grid-cols-5">
              <img
                className="col-span-2 services-icon transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                src={Img3}
                alt=""
              />
              <p className="services-text text-white text-left col-span-3 mt-4 ml-3">
                Developers' Training Hub
              </p>
            </div>
            <div className="mb-10 grid grid-cols-5">
              <img
                className="col-span-2 services-icon transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                src={Img4}
                alt=""
              />
              <p className="services-text text-white text-left col-span-3 mt-4 ml-3">
                Incubator & Accelerator Program
              </p>
            </div>
          </div>
        </div>
        <div className="community" id="community">
          <div className="lg:p-20 p-5 pt-10 ">
            <p className="community-heading text-white">JOIN OUR COMMUNITY!</p>
            <p className="community-text text-white mt-10">
              At Deverse Labs we operate as a hybrid company which allow our
              team work remotely and physically when the need to arises to; We
              also create an easy reaching method for client, community, project
              and corporate stakeholder who want to reach out and inquiries for
              advisors and product development.
            </p>
            <div className="social-icons place-content-center mt-20">
              <a href="https://t.me/deverselabs">
                <TbBrandTelegram className="text-secondary icon " />
              </a>
              <a href="https://www.instagram.com/deverselabs/">
                <FaInstagram className="text-secondary icon" />
              </a>
              <a href="https://discord.gg/rPDcdCED">
                <RxDiscordLogo className="text-secondary icon" />
              </a>
              <a href="https://linkedin.com/company/deverse-labs/">
                <SlSocialLinkedin className="text-secondary icon" />
              </a>
              <a href="https://twitter.com/deverse_labs">
                <FiTwitter className="text-secondary icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="team lg:p-20 col-span-7 p-10" id="portfolio">
          <p className="text-white team-heading">OUR PORTFOLIO</p>
          {/* <p className="team-text text-white mt-10">
            The team consist of sound entrepreneurs, angel investor, technical
            expert, media representatives and CEO’s of remarkables blockchain
            companies, listed as follows;
          </p> */}
          <div className="lg:grid lg:grid-cols-3 gap-10 pt-20">
            <Team
              picture={Img6}
              person="HealthGo"
              position="Africans gateway to fast and affordable healthcare needs"
              link="healthgotechnologies.com/"
              position2=""
            />
            <Team
              picture={Img5}
              person="Swyypepay"
              link="swyypepay.com/"
              position="Your portal to a Decentralized way of payment."
            />
            <Team
              picture={Img7}
              person="Groverse"
              link="groversetech.com/"
              position="Reneweable Energy meets Blockchain"
            />
            <Team
              picture={Img8}
              person="Medical Store"
              link="medicalstore.com.ng/"
              position="E-commerce place for medical product accepting payment in crypto"
            />
            <Team
              picture={Img9}
              person="Smart Builder"
              link="smartdappsbuilder.com/"
              position="The World Smartest Token and dApps builder"
            />
            <a href="https://shuriken-labs-dojo.web.app/" target="_blank">
              <div className="mb-10 grid grid-cols-5">
                <div className="col-span-2 flex items-center justify-center">
                  <img
                    className="team-icon transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                    src={Img10}
                    alt=""
                    style={{
                      margin: "auto",
                      maxHeight: "80px",
                      width: "auto",
                      filter: "invert(100%) grayscale(1)",
                    }}
                  />
                </div>

                <div className="team-text text-white text-left col-span-3 ml-3">
                  <p className="team-name">Shuriken Labs</p>
                  <p className="title text-primary">
                    Your digital dojo for mastering the art of web3.
                  </p>
                </div>
              </div>
            </a>
            <Team
              picture={Img11}
              person="Thrift Chain"
              link="thriftchain.org/"
              position="Your Contribution To Financial
              Independence"
            />

            <a href="https://content-lock.com" target="_blank">
              <div className="mb-10 grid grid-cols-5">
                <div className="col-span-2 flex items-center justify-center">
                  <img
                    className="team-icon transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                    src={Img13}
                    alt=""
                    style={{
                      margin: "auto",
                      maxHeight: "120px",
                      width: "auto",
                    }}
                  />
                </div>

                <div className="team-text text-white text-left col-span-3 ml-3">
                  <p className="team-name">ContentLock</p>
                  <p className="title text-primary">
                    Monetize Creatively, Earn Effortlessly with ContentLock!
                  </p>
                </div>
              </div>
            </a>
            {/* <Team
              picture={Img13}
              person="ContentLock"
              link="maroswaste.com.ng/"
              position="Your Contribution To Financial
              Independence"
            /> */}
            <Team
              picture={Img12}
              person="MarOs"
              link="maroswaste.com.ng/"
              position="Recreation from waste"
            />
          </div>
        </div>
        <div className="team lg:p-20 col-span-7 p-10" id="portfolio">
          <p className="text-white team-heading">OUR OFFICIAL PARTNER</p>
          <div className="mt-10 lg:grid grid-cols-3">
            <a href="https://toronet.org/" target="_blank">
              <img src={Img14} className="m-auto lg:h-20 h-18" />
            </a>
            <a href="https://workspaceglobal.com/" target="_blank">
              <img src={Img15} className="m-auto lg:h-20 h-18 mt-10 lg:mt-0" />
            </a>
            <a href="https://infusionlawyers.com/" target="_blank">
              <img src={Img16} className="m-auto lg:h-20 h-18 mt-10 lg:mt-0" />
            </a>
          </div>
        </div>
        <div id="contact" className="contact">
          <div className="lg:p-20 p-5">
            <p className="text-white contact-heading">DON’T BE A STRANGER</p>
            <p className="contact-text text-white mt-10">
              Our arms are open and we can’t wait to hear from you today as you
              take this bold step , we look forward to meeting you and your
              needs. Kindly use the text box and leave us a message ...cheers
            </p>
            <textarea
              className="message mt-10"
              placeholder="Message Here"
              name="postContent"
              rows={3}
              autoFocus={false}
            />
            <br />
            <textarea
              className="message mt-5"
              placeholder="Email Address"
              name="postContent"
              rows={1}
              autoFocus={false}
            />
            <br />
            <button className=" btn mt-5 text-white font-bold py-2 px-4 rounded ">
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
