import React from "react";

export default function Team({ person, position, position2, picture, link }) {
  return (
    <>
      <a href={`https://${link}`} target="_blank">
        <div className="mb-10 grid grid-cols-5">
          <img
            className="col-span-2 team-icon center-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            src={picture}
            alt=""
            style={{ margin: "auto", maxHeight: "80px", width: "auto" }}
          />

          <div className="team-text text-white text-left col-span-3 ml-3  ">
            <p className="team-name">{person}</p>
            <p className="title text-primary">{position}</p>
          </div>
        </div>
      </a>
    </>
  );
}
