import "./App.css";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Logo from "./Assets/Images/lg.png";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <FloatingWhatsApp
          phoneNumber="+2349074026790" // Required
          accountName="Deverse Labs" // Optional
          avatar={Logo} // Optional
          initialMessageByServer="Hi there! How can I assist you?" // Optional
          statusMessage="Available" // Optional
          placeholder="Write here..." // Optional
          allowEsc={true} // Optional
          darkMode={true}
          // Explore all available props below
        />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
